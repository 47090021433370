// app/design/frontend/Namespace/themename/web/js/main.js
// Usando libs que já tem no magento
require(['jquery', 'slick'], function ($) {
  console.log('Script do tema!');
  console.log(typeof $().slick === 'function');

  jQuery(function ($) {
    if (screen.width < 768) {
      let $searchBtn = $('#search-btn');
      let $searchContainer = $('.search-container');

      $searchBtn.on('click', function () {
        $searchContainer.toggleClass('active');
      });
    }

    $('.accordian-header').on('click', function () {
      $('.accordian-blog-list').slideToggle()
    })

    if (screen.width < 1440) {
      $('.hamburger').on('click', function () {
        $('.navigation').addClass('active');
        $('body').addClass('hidden');
      });
      $('.cross-btn').on('click', function () {
        $('.navigation').removeClass('active');
        $('body').removeClass('hidden')
      })
      $(".dropdown, .menu .parent .caret").on('click', function (e) {
        e.preventDefault();
        //$(this).next().addClass('active');
        $(this).parent().next('.submenu').addClass('active');
      })
      $('.back-btn, .back-button a').on('click', function () {
        $(this).parent().closest('.submenu').removeClass('active');
        //$(".submenu").removeClass('active');
      })
    }


    // tabbing
    if ($("ul.tabs").length) {
      $('ul.tabs li').on('click', function () {
        let tab_id = $(this).attr('data-tab');

        $('ul.tabs li').removeClass('current');
        $('.tab-content').removeClass('current');

        $(this).addClass('current');
        $("#" + tab_id).addClass('current');
      })
    }

    function updateClassesBasedOnScreenWidth() {
      let windowWidth = $(window).width();
      let $element = $('.filter-products-wrapper');
      if (windowWidth >= 1440) {
        $element.removeClass('double-view').addClass('three-view');
      } else {
        $element.removeClass('four-view').addClass('double-view');
      }
    }
    updateClassesBasedOnScreenWidth();

    $(window).on('resize', function () {
      updateClassesBasedOnScreenWidth();
    });


    if ($(".slider-for").length) {
      $('.slider-for').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        asNavFor: '.slider-nav'
      });
      $('.slider-nav').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '.slider-for',
        arrows: false,
        focusOnSelect: true
      });
    }
    // quantity
    if ($(".change-quantity").length) {
      $('.change-quantity').on('click', function () {
        let input = $(this).siblings('.quantity-input');
        let value = parseInt(input.val()) + parseInt($(this).data('value'));

        if (value < 1) {
          value = 1;
        }

        input.val(value);
      });
    }

    let $searchInput = $('#search-input');
    let $suggetion = $('.search-suggetion')
    $searchInput.on('focus', function () {
      $($suggetion).addClass('focused');
    });

    $searchInput.on('blur', function () {
      $($suggetion).removeClass('focused');
    });

  });


  jQuery(function ($) {

    let $swiperSelector = $(".swiper-container");
    $swiperSelector.each(function (index) {
      let $this = $(this);
      $this.addClass("swiper-slider-" + index);
      let freeMode = $this.data("free-mode") ? $this.data("free-mode") : false;
      let loop = $this.data("loop") ? $this.data("loop") : false;
      let slidesDesktop = $this.data("slides-desktop") ? $this.data("slides-desktop") : 7;
      let slidesLaptop = $this.data("slides-laptop") ? $this.data("slides-laptop") : 7;
      let slidesTablet = $this.data("slides-tablet") ? $this.data("slides-tablet") : 5.2;
      let slidesmobileLandscape = $this.data("slides-mobile-landscape") ? $this.data("slides-mobile-landscape") : 3.2;
      let slidesMobile = $this.data("slides-mobile") ? $this.data("slides-mobile") : 2.2;
      let spaceBetween = $this.data("space-between") ? $this.data("space-between") : 14;
      try {
        new Swiper(".swiper-slider-" + index, {
          direction: "horizontal",
          loop: loop,
          freeMode: freeMode,
          spaceBetween: spaceBetween,
          observer: true,
          observeParents: true,
          breakpoints: {
            1440: {
              slidesPerView: slidesDesktop,
            },
            1200: {
              slidesPerView: slidesLaptop,
            },
            992: {
              slidesPerView: slidesTablet,
            },
            600: {
              slidesPerView: slidesmobileLandscape,
            },
            320: {
              slidesPerView: slidesMobile,
              spaceBetween: 14,
            },
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }
        });
      }
      catch (e) {

      }
    });
  })


  jQuery(function ($) {
    var slickExists = typeof $().slick === 'function';

    if (slickExists) {
      var simpleSider = $('.simple-slider');

      if (simpleSider) {
        simpleSider.slick({
          dots: true
        });
      }

      var bannerCategory = $('.banner-category');
      if (bannerCategory.length) {
        bannerCategory.slick({
          infinite: false,
          slidesToShow: 2.3,
          slidesToScroll: 1,
          arrows: false,
          mobileFirst: true,
          //variableWidth: true,
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 7,
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 5.3,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 3.3,
              }
            },
            /*   {
                breakpoint: 320,
                settings: {
                  slidesToShow: 2.3,
                }
              } */
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
          ]
        });
      }
    }

    if ($(".swiper-container2").length) {
      try {
        new Swiper('.swiper-container2', {
          loop: false,
          breakpoints: {
            1440: {
              slidesPerView: 1,
            },
            1200: {
              slidesPerView: 1,
            },
            992: {
              slidesPerView: 1,
            },
            600: {
              slidesPerView: 1,
            },
            320: {
              slidesPerView: 1,
              spaceBetween: 14,
            },
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
        });
      }
      catch (e) {
      }
    }
  })



  jQuery(function ($) {
    $('.filter-btn').on('click', function () {
      $('.filter-sidebar').addClass('active');
      $('body').addClass('hidden')
    })
    $('.filter-close').on('click', function () {
      $('.filter-sidebar').removeClass('active');
      $('body').removeClass('hidden')
    })
    $('.filter-content').hide();
    $('.filter-content:lt(2)').show();
    $('.listing-header:lt(2)').addClass('active');
    $('.listing-header .open-listing').on('click', function () {
      const $filterContent = $(this).closest('li').find('.filter-content');
      const $filterHeader = $(this).closest('.listing-header');
      $filterContent.slideToggle();
      $filterHeader.toggleClass('active');
    });



    function addActiveClassOnWideScreens() {
      let windowWidth = $(window).width();
      let $element = $('.three-column-view');

      if (windowWidth >= 1200) {
        $element.addClass('active');
      } else {
        $element.removeClass('active');
      }
    }

    addActiveClassOnWideScreens();

    $(window).on('resize', function () {
      addActiveClassOnWideScreens();
    });

    function addActiveClassOnWideScreens2() {
      let windowWidth = $(window).width();
      let $element1 = $('.three-column-view');
      let $element = $('.four-column-view');

      if (windowWidth >= 1200) {
        $element1.addClass('active');
      } else {
        $element.removeClass('active');
      }
    }

    addActiveClassOnWideScreens2();

    $(window).on('resize', function () {
      addActiveClassOnWideScreens2();
    });


    const $viewButtons = $('.filter-view button');
    const $productGrid = $('.filter-products-wrapper');
    $viewButtons.filter('[data-view="double"]').addClass('active');
    $productGrid.addClass('double-view');
    $viewButtons.on('click', function () {
      const view = $(this).data('view');
      $viewButtons.removeClass('active');
      $(this).addClass('active');
      $productGrid.removeClass('single-view double-view triple-view four-view').addClass(view + '-view');
    });

  });


});


